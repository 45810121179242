import React from 'react';
// import styles from './header.css';

import Navbar from './Navbar/navbar';



const Header = (props) =>
{

    return(
        
        <header>
            <Navbar/> 
        </header>
    )
}

export default Header;