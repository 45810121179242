import React from "react";
import { Link } from "react-router-dom";
import styles from "./nomatch.css";

const NoMatch = () => {
  return (
    <>
      <div className={styles.container404}>
        <h1>
          Oups, il semblerait que l'URL recherchée ne soit plus disponible
        </h1>
        <Link to={"/"}>Revenir à la page d'accueil</Link>
      </div>
    </>
  );
};

export default NoMatch;
